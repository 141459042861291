import {stringApp} from 'constant';
export default {
  footerapp: {
    OverviewScreen: `Tổng quan`,
    TransactionScreen: `Giao dịch`,
    InvestmentScreen: `Sản phẩm`,
    AssetScreen: `Tài sản`,
    ProfileScreen: `Tài khoản`,
  },
  splashscreen: {
    hi: `Chào mừng bạn đến với ${stringApp.companyName}`,
    continue: `Tiếp tục`,
  },
  loginscreen: {
    login: `Đăng nhập`,
    saveusername: `Lưu`,
    forgotpass: `Quên mật khẩu ?`,
    signuptitle: `Bạn chưa có tài khoản? `,
    signup: `Đăng ký ngay!`,
    accountplacehoder: `Nhập số điện thoại`,
    passwordplacehoder: `Nhập mật khẩu`,
    fincorp: `${stringApp.companyName}`,
    thefintechdeveloper: `The fintech developer`,
    welcome: `Welcome to ${stringApp.companyName}!`,
    tendangnhap: `Tên đăng nhập`,
    matkhau: `Mật khẩu`,
    kichhoattaikhoan: `Kích hoạt tài khoản`,
  },
  reviewinfoscreen: {
    thongtinbosung: `Thông tin bổ sung`,
    hosocanhan: `Hồ sơ cá nhân`,
    thongtincanhan: `Thông tin cá nhân`,
    hoantathosodangky: `Hoàn tất hồ sơ đăng ký`,
    thongtintaikhoannganhang: `Thông tin tài khoản ngân hàng`,
    tenchutaikhoan: `Tên chủ tài khoản`,
    sotaikhoan: `Số tài khoản`,
    tennganhang: `Tên ngân hàng`,
    cccd: `CMND/CCCD`,
    chinhanh: `Chi nhánh`,
    thongtinnhadautu: `1. Thông tin nhà đầu tư`,
    danhgiamucdoruiro: `Đánh giá mức độ rủi ro`,
    hoten: `Họ tên`,
    gioitinh: `Giới tính`,
    ngaysinh: `Ngày sinh`,
    quoctich: `Quốc tịch`,
    email: `Email`,
    sodienthoai: `Số điện thoại`,
    thongtingiayto: `2. Thông tin giấy tờ tuỳ thân/mã GD chứng khoán`,
    note: `Chú ý: Mã giao dịch chứng khoán chỉ dành cho người nước ngoài`,
    loaigiayto: `Loại giấy tờ`,
    sohieugiayto: `Số hiệu giấy tờ`,
    ngaycap: `Ngày cấp`,
    noicap: `Nơi cấp`,
    taihinhanh: `Tải hình ảnh giấy tờ tuỳ thân/Mã GD chứng khoán của bạn`,
    thongtindiachi: `Thông tin địa chỉ`,
    diachithuongtru: `1. Địa chỉ thường trú`,
    quocgia: `Quốc gia`,
    tinhthanhpho: `Tỉnh/Thành phố`,
    quanhuyen: `Quận/huyện`,
    phuongxa: `Phường/Xã`,
    sonhatenduong: `Số nhà, tên đường`,
    diachilienhe: `2. Địa chỉ liên hệ`,
    giongdiachithuongtru: `Giống địa chỉ thường trú`,
    diachikhac: `Địa chỉ khác`,
    trong: ``,
    dieukhoandieukienmotaikhoan: `Điều khoản điều kiện mở tài khoản`,
    dieukhoansudung: `Điều khoản sử dụng`,
    hoantat: `Hoàn tất`,
  },
  forgotpasswordscreen: {
    forgotpassword: `Quên mật khẩu`,
    content: `Bạn vui lòng nhập tên đăng nhập đã đăng ký và thực hiện theo hướng dẫn.`,
    continue: `Tiếp tục`,
    tendangnhap: `Tên đăng nhập`,
  },
  registerscreen: {
    createaccount: `Tạo tài khoản`,
    fullname: `Họ và tên đầy đủ`,
    email: `Email`,
    phonenumber: `Số điện thoại`,
    userrefcode: `Người giới thiệu`,
    continue: `Tiếp tục`,
    tieptuc: `Tiếp tục`,
    signintitle: `Bạn đã có tài khoản? `,
    signin: `Đăng nhập`,
    dangnhap: `Đăng nhập!`,
    dangki: `Đăng ký`,
    hoantoanmienphivaratdongian: `Miễn phí - Đơn giản - Dễ dàng`,
    hotendaydu: `Họ tên đầy đủ`,
    magioithieucuanguoibanhang: `Mã giới thiệu của người bán hàng`,
    thongtinbatbuoc: `Thông tin bắt buộc`,
    tendaydubatbuocnhap: `Tên đầy đủ bắt buộc nhập`,
    saidinhdangemail: `Sai định dạng email`,
    saisodienthoai: `Sai số điện thoại`,
    thongtinkhongduocdetrong: `Thông tin không được để trống`,
    titletrong: ``,
    emailkhongduocdetrong: `Email bắt buộc nhập`,
    sodienthoaikhongduocdetrong: `Số điện thoại bắt buộc nhập`,
    khachhanghienhuu: `Khách hàng hiện hữu của ${stringApp.tellerCode}`,
    khachhangchuaphai: `Khách hàng chưa phải là Khách hàng của ${stringApp.tellerCode}`,
    vidu: 'Ví dụ',
  },
  activeaccountscreen: {
    kichhoattaikhoan: `Kích hoạt tài khoản`,
    titletrong: ``,
    sodienthoaidadangky: `Số điện thoại đã đăng ký`,
    placehoder: `Số điện thoại, ví dụ: 09123456789`,
    tieptuc: `Tiếp tục`,
    contentactive: `Quý khách vui lòng cung cấp đúng số điện thoại đã đăng ký tài khoản giao dịch`,
  },
  setpasswordscreen: {
    craetepass: `Tạo mật khẩu`,
    baomatthongtincanhan: `Bảo mật thông tin cá nhân`,
    rule: `Chiều dài tối thiểu là 6, bao gồm chữ thường (a-z) và chữ in hoa (A-Z), chứa ít nhất một số (0-9) hoặc ký hiệu đặc biệt`,
    namelogin: `Tên đăng nhập`,
    password: `Mật khẩu`,
    repassword: `Nhập lại mật khẩu`,
    accept: `Chấp nhận`,
    create: `Đăng ký`,
    dangnhap: `Đăng nhập`,
    taomatkhau: `Tạo mật khẩu `,
    tendangnhap: `Tên đăng nhập`,
    rule1: `Chiều dài tối thiểu là 6 và tối đa là 40`,
    rule2: `Bao gồm chữ thường (a-z) và chữ in hoa (A-Z)`,
    rule3: `Chứa ít nhất một số (0-9) hoặc ký hiệu đặc biệt`,
    matkhau: `Mật khẩu`,
    nhaplaimatkhau: `Nhập lại mật khẩu`,
    matkhaubaogom: `Mật khẩu bao gồm:`,
    tieptuc: `Tiếp tục`,
    saidinhdangmatkhau: `Sai định dạng mật khẩu!`,
    xacnhanmatkhaukhongdung: `Xác nhận mật khẩu không đúng!`,
    titletrong: ``,
    thongtinkhongduocdetrong: `Thông tin không được để trống`,
    vuilongnhapmatkhau: `Vui lòng nhập mật khẩu`,
    vuilongnhaplaimatkhau: `Vui lòng nhập lại mật khẩu`,
    xacthuctaikhoan: `Xác thực tài khoản`,
  },
  otprequestmodal: {
    confirminformation: `Xác thực giao dịch`,
    content: `Vui lòng nhập mã xác thực (OTP) đã được gửi đến số điện thoại `,
    confirm: `Xác thực`,
    resendotp: `Gửi lại mã OTP`,
    contenttiming: `Mã OTP sẽ hết hạn trong vòng `,
    otptimeout: `Mã OTP đã hết hạn`,
    vuilongnhapmapinsmartotp: `Vui lòng nhập mã PIN Smart OTP`,
  },
  loading: `Đang tải`,
  profile: {
    taikhoan: `Tài khoản`,
    file: `Hồ sơ cá nhân`,
    logininfo: `Thông tin đăng nhập`,
    changepassword: `Thay đổi mật khẩu`,
    hosogoc: `Hồ sơ gốc`,
    changeemail: `Thay đổi email`,
    changepermanentaddress: `Thay đổi địa chỉ thường trú`,
    changeaddress: `Thay đổi địa chỉ liên hệ`,
    logout: `Đăng xuất`,
    hosomotaikhoan: `Hồ sơ mở tài khoản`,
    sotaikhoan: `Số TK - `,
    contentlogininfo: `Bạn có muốn lưu thông tin đã thay đổi không?`,
    change: `Thay đổi`,
    email: `Email`,
    phone: `Số điện thoại`,
    password: `Mật khẩu`,
    userref: `Người giới thiệu`,
    cannotchange: `Không thể thay đổi`,
    chukyso: `Chữ ký số`,
    trangthaihoso: `Trạng thái hồ sơ`,
    thongtintaikhoan: `Hồ sơ cá nhân`,
    thaydoimatkhau: `Thay đổi mật khẩu`,
    thaydoisodienthoai: `Thay đổi số điện thoại`,
    thaydoiemail: `Thay đổi email`,
    hotrokhachhang: `Hỗ trợ khách hàng`,
    hotline: `Hotline`,
    caidatvabaomat: `Cài đặt & bảo mật`,
    dangxuat: `Đăng xuất`,
    oldpassword: `Mật khẩu cũ`,
    newpassword: `Mật khẩu mới`,
    renewpassword: `Xác nhận mật khẩu`,
    save: `Lưu`,
    rulepassword: `Mật khẩu bao gồm \n* Chiều dài tối thiểu là 6\n* Bao gồm chữ thường (a-z) và chữ in hoa (A-Z)\n* Chứa ít nhất một số (0-9) hoặc một kí tự đặc biệt `,
    oldemail: `Email cũ`,
    newemail: `Email mới`,
    renewemail: `Xác nhận email`,
    address: `Số nhà và tên đường`,
    placeholderaddress: `Điền số nhà, tên đường`,
    nation: `Quốc gia`,
    city: `Tỉnh/Thành phố`,
    district: `Quận/Huyện`,
    commune: `Phường/Xã`,
    emailsaidinhdang: `Email sai định dạng`,
    matkhauchuadumanh: `Mật khẩu chưa đủ mạnh`,
    saidinhdangmatkhau: `Sai định dạng mật khẩu!`,
    xacnhanmatkhaukhongdung: `Xác nhận mật khẩu không đúng!`,
    titletrong: ``,
    thongtinkhongduocdetrong: `Thông tin không được để trống`,
  },
  accountverify: {
    thongtintaikhoan: `Thông tin tài khoản`,
    sothithucnhapcanh: `Số thị thực nhập cảnh`,
    thongtintochuc: `Thông tin tổ chức`,
    thongtintaikhoannganhang: `1. Thông tin tài khoản ngân hàng`,
    thongtinkhac: `2. Thông tin khác`,
    thongtincanhan: `Thông tin cá nhân`,
    thongtinnganhang: `Thông tin tài khoản ngân hàng`,
    thongtindiachi: `Thông tin địa chỉ`,
    xacthuchoantat: `Xác nhận hoàn tất`,
    thongtinbosung: `Thông tin bổ sung`,
    hopdongdientu: `Giấy đăng ký mở tài khoản giao dịch`,
    giaykhaosatipo: `Phiếu lấy ý kiến NĐT giai đoạn IPO`,
    vuilongchonquocgia: `Vui lòng chọn quốc gia`,
    vuilongchontinhthanhpho: `Vui lòng chọn tỉnh/thành phố`,
    vuilongchonquanhuyen: `Vui lòng chọn quận/huyện`,
    vuilongchonphuongxa: `Vui lòng chọn phường xã`,
    chondiachilienhe: `Chọn địa chỉ lên hệ`,
    vuilongchondiachilienhe: `Vui lòng chọn địa chỉ liên hệ`,
    danhgiamucdoruiro: `Đánh giá mức độ rủi ro`,
    guithongtin: `Gửi thông tin`,
    nghenghiep: `Nghề nghiệp`,
    chucvu: `Chức vụ`,
    mucthunhaphangthang: `Mức thu nhập hàng tháng`,
    nguontiendautu: `Nguồn tiền đầu tư`,
    sonhatenduong: `Số nhà, tên đường`,
    hosogoc: `Hồ sơ gốc`,
    fatca1:
      'Nhà đầu tư là công dân Mỹ hoặc tổ chức được thành lập hoặc có hoạt động tại Mỹ (FATCA)',
    fatca2:
      'Nhà đầu tư không là công dân Mỹ hoặc tổ chức được thành lập hoặc có hoạt động tại Mỹ (NONE FATCA)',
    quocgia: `Quốc gia`,
    tinhthanhpho: `Tỉnh/Thành phố`,
    tinhthanhphotheodiachilienhe: `Tỉnh/Thành phố (theo địa chỉ liên hệ)`,
    quanhuyen: `Quận/Huyện`,
    phuongxa: `Phường/Xã`,
    thongtinnhadautu: `1. Thông tin nhà đầu tư`,
    hoten: `Họ tên`,
    ngaysinh: `Ngày sinh`,
    gioitinh: `Giới tính`,
    quoctich: `Quốc tịch`,
    nam: `Nam`,
    nu: `Nữ`,
    email: `Email`,
    sodienthoai: `Số điện thoại`,
    thongtincmnd: `2. Thông tin giấy tùy thân/mã GD chứng khoán`,
    loaigiayto: `Loại giấy tờ`,
    sohieugiayto: `Số hiệu giấy tờ`,
    ngaycap: `Ngày cấp`,
    cmnd: `CMND/CCCD`,
    magiaodichchungkhoan: `Mã giao dịch chứng khoán`,
    noicap: `Nơi cấp`,
    taihinhanh: `Tải hình ảnh giấy tờ tùy thân/Mã GD chứng khoán của bạn`,
    notetaihinhanh: `Giấy tờ còn hạn sử dụng, đủ ánh sáng, là hình gốc, không scan và photocopy`,
    taianhmattruoc: `Tải ảnh mặt trước`,
    taianhmatsau: `Tải ảnh mặt sau`,
    vuilongchonloaigiayto: `Vui lòng chọn loại giấy tờ`,
    chuy: `Chú ý: Mã giao dịch chứng khoán chỉ dành cho người nước ngoài`,
    tenchutaikhoan: `Tên chủ tài khoản`,
    sotaikhoan: `Số tài khoản`,
    tennganhang: `Tên ngân hàng`,
    chinhanh: `Chi nhánh`,
    content: `Thông tin bắt buộc và thông tin tài khoản này được chuyển khoản khi thực hiện lệnh bán.`,
    vuilongchonnganhang: `Vui lòng chọn ngân hàng`,
    vuilongchonchinhanh: `Vui lòng chọn chi nhánh`,
    diachilienhe: `Địa chỉ liên hệ`,
    giongdiachithuongtru: `Giống địa chỉ thường trú`,
    diachikhac: `Địa chỉ khác`,
    diachithuongtru: `Địa chỉ thường trú`,
    dieukhoan: `Điều khoản`,
    xacnhanhoantat: `Xác nhận hoàn tất`,
    hoantat: `Hoàn tất`,
    toidongyvoidieukhoantren: `Tôi đồng ý với điều khoản trên`,
    contentinhoso: `Quý khách vui lòng in, ký xác nhận và gửi thư về địa chỉ của công ty trong phần liên hệ!`,
    tongdongyvoidieukhoanfatca: `Tôi đồng ý trả lời: Không cho tất cả các câu hỏi liên quan đến FATCA`,
    contentdiachi1: `Sau khi hoàn thành bước xác nhận này, thông tin hợp đồng mở tài khoản sẽ được gửi về email: `,
    titleconfirm: `Để bắt đầu thực hiện giao dịch, bạn cần xác nhận thông tin và đồng ý với các điều khoản và điều kiện bên dưới:`,
    save: `Lưu`,
    //
    thongtinphongchongruatien: `1. Thông tin phòng chống rửa tiền`,
    nhadautucophailanguoinhanuythac: `Nhà đầu tư có phải là người nhận ủy thác của bất kỳ tổ chức/cá nhân nào ở nước ngoài ?`,
    hovaten: `Họ và tên`,
    Email: `Email`,
    sofax: `Số fax`,
    sohohieugiaychungnhandkgd: `Số hộ chiếu/GCN ĐKGD`,
    masothue: `Mã số thuế`,
    quocgiadongthue: `Quốc gia đóng thuế`,
    diachi: `Địa chỉ`,
    loaihinhnhadautu: `2. Loại hình nhà đầu tư`,
    nhadautuchungkhoankhongchuyenghiep: `Nhà đầu tư chứng khoán không chuyên nghiệp`,
    nhadautuchungkhoanchuyennghiep: `Nhà đầu tư chứng khoán chuyên nghiệp`,
    co: `Có`,
    khong: `Không`,
    source: `source`,
    monthlyIncom: `monthlyIncom`,
    chinhsachcotuc: `Chính sách cổ tức`,
    dividendcontent: `Nhà đầu tư có thể chọn cổ tức bằng tiền mặt chuyển vào tài khoản hoặc tái đầu tư. Cổ tức sẽ được tái đầu tư nếu không có phương thức nào được chọn`,
  },
  transactionscreen: {
    ORDER_INIT: `Khởi tạo`,
    ORDER_PENDING: `Chờ duyệt`,
    ORDER_APPROVE: `Chờ khớp lệnh`,
    ORDER_CANCEL: `Đã hủy`,
    ORDER_ACCEPT: `Chờ khớp lệnh`,
    ORDER_RECONCILED: `Đã khớp`,
    ORDER_PLEDGE: `Đã thế chấp`,
    ORDER_CANCEL_PLEDGE: `Hủy thế chấp`,
    ORDER_REJECT: `Không khớp lệnh`,
    saokegiaodich: `Sao kê giao dịch`,
    chonquy: `Chọn quỹ`,
    theothoidiemgiaodich: `Theo thời điểm giao dịch`,
    saoke: `Sao kê`,
    tungay: `Từ ngày`,
    denngay: `Đến ngày`,
    giaodich: `Giao dịch`,
    lenhchoxuly: `Lệnh chờ xử lý`,
    lichsugiaodich: `Lịch sử giao dịch`,
    lenhchomua: `Lệnh chờ mua`,
    lenhchoban: `Lệnh chờ bán`,
    lenhchochuyendoi: `Lệnh chờ chuyển đổi`,
    tongsolenh: `Tổng số lệnh: `,
    quychuongtrinh: `Quỹ - Chương trình`,
    soluong: `Số lượng`,
    sotienmua: `Số tiền mua`,
    sotienmuavnd: `Số tiền mua ${stringApp.currency}`,
    ngaydatlenh: `Ngày đặt lệnh`,
    trangthai: `Trạng thái`,
    nhapmalenh: `Nhập mã lệnh`,
    ngay: `Ngày`,
    thoidiemgiaodich: `Thời điểm giao dịch`,
    chuacogiaodich: `Hiện tại quý khách chưa tạo lệnh`,
    thongtindautu: `Thông tin đầu tư`,
    quydautu: `Quỹ đầu tư`,
    chuongtrinh: `Chương trình`,
    loailenh: `Loại lệnh`,
    phiengiaodich: `Phiên giao dịch`,
    thongtinchuyenkhoan: `Thông tin chuyển khoản`,
    taikhoanthuhuong: `Tài khoản thụ hưởng`,
    tenthuhuong: `Tên thụ hưởng`,
    sotaikhoan: `Số tài khoản`,
    tennganhang: `Tên ngân hàng`,
    noidung: `Nội dung`,
    phuongthucchuyenkhoan: `Bạn đang chọn phương thức chuyển khoản qua ngân hàng.`,
    soccqban: `Số CCQ Bán`,
    soccq: `Số CCQ`,
    ngaymua: `Ngày mua`,
    tgnamgiu: `Thời gian nắm giữ`,
    slban: `Số lượng bán`,
    phi: `Phí`,
    quymuctieu: `Quỹ mục tiêu`,
    giatriuoctinh: `Giá trị ước tính`,
    socqqchuyendoi: `Số CQQ chuyển đổi`,
    tongtien: `Tổng tiền`,
    ngaygiaodich: `Ngày giao dịch`,
    malenh: `Mã lệnh`,
    nav: `NAV`,
    sotien: `Số tiền`,
    navkytruoc: `NAV kỳ trước`,
    locgiaodich: `Lọc giao dịch`,
    xoa: `Xoá`,
    huy: `Huỷ`,
    apdung: `Áp dụng`,
    theoloailenh: `Theo loại lệnh`,
    tatca: `Tất cả`,
    lenhmua: `Lệnh mua`,
    lenhban: `Lệnh bán`,
    lenhmuahoandoi: `Lệnh chuyển đổi mua`,
    lenhbanhoandoi: `Lệnh chuyển đổi bán`,
    lenhhoandoi: `Lệnh hoán đổi`,
    toingay: `Tới ngày`,
    taolenhmua: `Tạo lệnh mua`,
    taolenhban: `Tạo lệnh bán`,
    lenhchuyendoi: `Lệnh chuyển đổi`,
    taolenhchuyendoi: `Tạo lệnh chuyển đổi`,
    dinhky: `Quản lý định kỳ`,
    tinhtrang: `Tình trạng`,
    sotiendangkydautu: `Số tiền đăng ký đầu tư`,
    sokydaututoithieu: `Số kỳ đầu tư tối thiểu`,
    sokydadautu: `Số kỳ đã đầu tư`,
    sokylientuckhongthamgia: `Số kỳ liên tục không tham gia`,
    kydaututieptheo: `Kỳ đầu tư tiếp theo`,
    copy: `COPY`,
    nganhang: `Ngân hàng`,
    chinhanh: `Chi nhánh`,
    nguoihuongthu: `Người hưởng thụ`,
    lichsukhoplenh: `Lịch sử khớp lệnh`,
    taiphieuxacnhan: `Tải phiếu xác nhận`,
    kydautu: `Kỳ đầu tư`,
    sotiendautu: `Số tiền đầu tư`,
    ngaykhoplenh: `Ngày khớp lệnh`,
    danhantien: 'Đã nhận tiền',
    choxacnhantien: 'Chờ xác nhận tiền',
  },
  settingscreen: {
    caidatvabaomat: `Cài đặt & bảo mật`,
    ngonngu: `Ngôn ngữ`,
    nhanthongbao: `Nhận thông báo`,
    faceid: `Khuôn mặt/Vân tay`,
    thongbao: `Thông báo`,
    content: `Quý khách vui lòng nhập mật khẩu để kích hoạt chức năng này`,
    matkhau: `Mật khẩu`,
    huy: `Huỷ`,
    xacnhan: `Xác nhận`,
    kichhoatthanhcong: `Quý khách vừa kích hoạt đăng nhập bằng vân tay/khuôn mặt thành công`,
    xacnhanhuykichhoat: `Bạn có chắc chắn muốn huỷ cài đặt vân tay/khuôn mặt?`,
    matkhaucuaquykhachkhongdung: `Mật khẩu của Quý khách không đúng`,
    yeucauxoataikhoan: `Yêu cầu xoá tài khoản`,
    yeucauxoa: `Yêu cầu xoá`,
    content1: `<p>Tài khoản sau khi xoá không thể khôi phục lại được</p>`,
    content2: `<p>Sau khi tài khoản bị xoá, bạn <strong>không</strong> thể tạo lại tài khoản với <strong>số điện thoại</strong> hoặc số <strong>CMND/CCCD</strong> này được.</p>`,
    content3: `<p>Tài khoản chỉ được xoá khi không còn tài sản tại ${stringApp.companyName}.</p>`,
  },
  supportscreen: {
    hotrokhachhang: `Hỗ trợ khách hàng`,
    cskh: `CSKH`,
    email: `Email`,
    facebook: `Facebook`,
    trungtamchamsockhachhang: `Trung tâm chăm sóc khách hàng`,
    chuyenvientrogiup: `Chuyên viên trợ giúp`,
    hoten: `Họ tên`,
  },
  investmentscreen: {
    dautu: `Đầu tư`,
    sanpham: `Sản phẩm`,
    giadonviquykytruoc: `Giá đơn vị quỹ kỳ trước`,
    ngay: `Ngày`,
    dautungay: `Đầu tư ngay`,
    giatrihientai: `Giá trị hiện tại`,
    danhsachphiengiaodich: `Danh sách phiên giao dịch`,
    phiengiaodich: `Phiên giao dịch`,
    taolenhmua: `Tạo lệnh mua`,
    thaydoisovoidaunam: `Kết quả đầu tư từ khi thành lập`,
    taingay: `tại ngày`,
    Taingay: `Tại ngày`,
    giatriNAVCCQ: `Giá đơn vị quỹ gần nhất ${stringApp.currency}`,
    NAVtaingay: `NAV tại `,
    tangtruongNAVCCQ: `Tăng trưởng NAV/CCQ - `,
    NAVcaonhat: `NAV\ncao nhất`,
    NAVthapnhat: `NAV\nthấp nhất`,
    khongconoidunghienthi: `Không có nội dung hiển thị`,
    thang: `Tháng`,
    nam: `Năm`,
    tatca: `Tất cả`,
    NAV: `NAV`,
    navccq: `NAV/CCQ`,
    thongtinquy: `Thông tin quỹ`,
    xemchitiet: `Xem chi tiết`,
    xemthem: `Xem thêm`,
    trangthai: `Trạng thái `,
    phieulayykienndt: `Phiếu lấy ý kiến NĐT giai đoạn IPO: `,
  },
  createordermodal: {
    sotiendangkymualaboisocua: `Số tiền đăng ký mua là bội số của`,
    huongdanthanhtoanbangqrcode: `Hướng dẫn thanh toán bằng QR Code`,
    giatrimua: `Giá trị mua`,
    nhapmatuvanvien: `Nhập mã tư vấn viên`,
    tudongtieptucdautu: `Tự động tiếp tục đầu tư`,
    ngaythanhtoanhangthang: `Ngày thanh toán hàng tháng`,
    datlenhban: `Đặt lệnh bán`,
    datlenhmua: `Đặt lệnh mua`,
    datlenhchuyendoi: `Đặt lệnh chuyển đổi`,
    matuvanvien: `Mã tư vấn viên`,
    xacnhan: `Xác nhận`,
    tieptuc: `Tiếp tục`,
    datlenh: `Đặt lệnh`,
    ketqua: `Kết quả`,
    hoac: `Hoặc`,
    thanhtoannhanhquaqrcode: `Thanh toán nhanh qua QR Code`,
    huongdanchuyenkhoan: `Hướng dẫn chuyển khoản`,
    xemqrcode: `Xem QR code`,
    taive: `Tải về`,
    motsonganhangchuahotroquetmaqr: `Một số ngân hàng chưa hỗ trợ quét mã QR`,
    quykhachvuilongdoichieuthongtintruockhichuyenkhoan: `Quý khách vui lòng đối chiếu Thông tin trước khi chuyển khoản`,
    quaylai: `Quay lại`,
    bieuphimua: `Biểu phí mua`,
    thoigiandautulientuctoithieu: `Thời gian đầu tư liên tục tối thiểu`,
    thang: `tháng`,
    bieuphichuyendoi: `Biểu phí chuyển đổi`,
    bieuphiban: `Biểu phí bán`,
    thoigiannamgiu: `Thời gian nắm giữ`,
    ngaybatdauthamgia: `Ngày bắt đầu tham gia`,
    tudongtaolenhdinhkyhangthang: `Tự động tạo lệnh định kỳ hàng tháng`,
    chonsanpham: `Chọn sản phẩm`,
    chonchuongtrinh: `Chọn chương trình`,
    nhapsotienmua: `Nhập số tiền mua`,
    sotiendaututoithieu: `Số tiền đầu tư tối thiểu`,
    phimua: `Phí mua`,
    phichuyendoi: `Phí chuyển đổi`,
    xembieuphi: `Xem biểu phí`,
    thoidiemdongsolenh: `Thời điểm đóng sổ lệnh `,
    nhadautuvuilongchuyentientruoc: `Nhà đầu tư vui lòng chuyển tiền trước`,
    thoidiemdongsolenhnhantien: `Tiền mua của Nhà đầu tư phải được ghi có vào tài khoản của Quỹ trước`,
    phiengiaodich: `Phiên giao dịch`,
    thongtindautu: `Thông tin đầu tư`,
    quydautu: `Quỹ đầu tư`,
    chuongtrinh: `Chương trình`,
    loailenh: `Loại lệnh`,
    ngaydatlenh: `Ngày đặt lệnh`,
    sotienmua: `Số tiền mua`,
    phuongphapthanhtoan: `Phương thức thanh toán`,
    chuyenkhoanquanganhang: `Chuyển khoản qua ngân hàng`,
    vuilongchuyentientruoc: `Vui lòng chuyển tiền trước`,
    thongtinchuyenkhoan: `Thông tin chuyển khoản`,
    luuyttck: `Vui lòng copy chính xác các thông tin để đảm bảo lệnh được khớp.`,
    tenthuhuong: `Tên thụ hưởng`,
    sotaikhoan: `Số tài khoản`,
    nganhang: `Ngân hàng`,
    noidung: `Nội dung`,
    mua: `Mua`,
    datlenhmuathanhcong: `Đặt lệnh mua thành công`,
    camonquykhachdadautuvao: `Cảm ơn quý khách đã đầu tư vào`,
    muathem: `Mua thêm`,
    hoantat: `Hoàn tất`,
    xacnhanthanhtoan: `Xác nhận thanh toán`,
    contentxacnhanthanhtoan: `Quý khách xác nhận thông tin thanh toán cho khoản đầu tư với thông tin chuyển khoản dưới đây:`,
    nhapsoluongban: `Nhập số lượng bán`,
    soluongtoithieukhongduoi: `Số lượng tối thiểu không dưới`,
    soluongkhadung: `Số lượng khả dụng`,
    ngaymua: `Ngày mua`,
    tgnamgiu: `Thời gian nắm giữ`,
    slban: `Số lượng bán`,
    phi: `Phí`,
    phiban: `Phí bán`,
    soluongban: `Số lượng bán`,
    ban: `Bán`,
    giatriuoctinhsauthuephi: `Giá trị ước tính sau thuế, phí`,
    datlenhbanthanhcong: `Đặt lệnh bán thành công`,
    camonquykhach: `Cảm ơn Quý khách`,
    thongtinccqchuyendoi: `Thông tin CCQ chuyển đổi`,
    chonccqchuyendoi: `Chọn quỹ chuyển đổi`,
    navkitruoc: `NAV kỳ trước`,
    soluongchuyendoi: `Số lượng chuyển đổi`,
    thongtinccqmuctieu: `Thông tin CCQ mục tiêu`,
    chonccqmuctieu: `Chọn quỹ mục tiêu`,
    thongtinchuyendoi: `Thông tin chuyển đổi`,
    chuyendoi: `Chuyển đổi`,
    datlenhchuyendoithanhcong: `Đặt lệnh chuyển đổi thành công`,
    navccqkitruoc: `NAV/CCQ kỳ trước`,
    tatca: `Tất cả`,
    khoplenhtheosotienchuyentienthucthe: `Thực hiện lệnh theo số tiền chuyển thực tế`,
    thoigianchuyentien:
      'Quý Nhà Đầu Tư vui lòng chuyển tiền vào tài khoản trước 14h30 ngày 15 hàng tháng',
    co: `Có`,
  },
  assetscreen: {
    tongquantaisan: `Tổng quan tài sản`,
    sl: `SL : `,
    chitiet: `Chi tiết`,
    ccq: `CCQ`,
    giamua: `Giá mua`,
    danhsachlenhmuadangnamgiu: `Danh sách lệnh mua đang nắm giữ`,
    tongsodanhsach: `Tổng số danh sách:`,
    quychuongtrinh: `Quỹ - Chương trình`,
    phiengiaodich: `Phiên giao dịch`,
    tonggiatridangnamgiu: `Tổng giá trị đang nắm giữ`,
    tonggiatridangchokhop: `Tổng giá trị lệnh bán đang chờ khớp`,
    tongtaisan: `Tổng tài sản`,
    soluong: `Số lượng`,
    giatridaututb: `Giá trị đầu tư trung bình`,
    navkitruoc: `NAV kỳ trước`,
    giatrihientai: `Giá trị hiện tại`,
    loilophantram: `Lời/Lỗ(%)`,
    loilodong: `Lời/Lỗ(đ)`,
    lenh: `Lệnh`,
    chuongtrinh: `Chương trình`,
    sotkdautu: `Số TK đầu tư`,
    giatrithitruong: `Giá trị thị trường`,
    sldonviquy: `SL đơn vị quỹ`,
    sotiendadautu: `Số tiền đã đầu tư`,
    sotiendadautuconlai: `Số tiền đã đầu tư còn lại`,
    ngaygiaodichdautien: `Ngày giao dịch đầu tiên`,
    lailodathuchien: `Lãi/Lỗ đã thực hiện`,
    lailochuathuchien: `Lãi/Lỗ chưa thực hiện`,
    loilo: `Lời/Lỗ`,
    tongdautu: `Tổng đầu tư`,
  },
  overviewscreen: {
    tongquantaisan: `Tổng quan tài sản`,
    tongsocqq: `Tổng số CQQ`,
    giatrihientai: `Giá trị hiện tại`,
    loilo: `Lời/lỗ`,
    banchuacotaisan: `Bạn chưa có tài sản`,
    contentnondata: `Bạn cần tham gia đầu tư thực hiện các giao dịch bằng cách vào mục "Đầu tư" bên dưới`,
    tonggiatridaututb: `Tổng giá trị đầu tư TB`,
    sotiendautu: `Tổng giá vốn`, // `Số tiền đầu tư (VNĐ)`,
    sotiendautuvnd: `Tổng giá vốn ${stringApp.currency}`, // `Số tiền đầu tư (VNĐ)`,
    tongloilo: `Tổng lời/lỗ`,
    taisan: `Tài sản`,
    soluongCCQ: `Số lượng CCQ`,
    NAVhientai: `NAV`,
    tonggiatridaututrungbinh: `Tổng giá vốn ${stringApp.currency}`,
    giamuatrungbinh: `Giá mua trung bình/CCQ`,
    tonggiatrithitruong: `Tổng giá trị thị trường ${stringApp.currency}`,
    ccq: 'CCQ',
  },
  notificationscreen: {
    thongbao: `Thông báo`,
    huy: `Huỷ`,
    xoamucdachon: `Xoá mục đã chọn`,
  },
  MiniEkycModal: {
    xacthucthongtin: `Xác thực thông tin`,
  },
  controlekyc: {
    dangnhap: `Đăng nhập`,
    xacthucthongtin: `Xác thực thông tin - eKYC`,
    content: `Để hoàn tất việc mở tài khoản trên ${stringApp.appName}, vui lòng thực hiện các bước xác thực thông tin dưới đây`,
    chuphinhmattruoc: `1. Chụp hình mặt trước, mặt sau CMND/CCCD`,
    chuphinhchandung: `2. Chụp hình chân dung`,
    batdau: `Bắt đầu`,
    thuchiensau: `Thực hiện sau`,
  },
  digitalsignature: {
    chukyso: `Chữ ký số`,
    xemgiaydkgd: `Xem giấy ĐKGD`,
    uploadanh: `Upload ảnh`,
    kyso: `Ký số`,
    xoa: `Xoá`,
    xacnhan: `Xác nhận`,
    contentnoti: `Quý khách hàng vui lòng ký vào khu vực trên đây`,
    giaydkgd: `Giấy ĐKGD`,
    mayanh: `Máy ảnh`,
    thuvien: `Thư viện`,
    huy: `Huỷ`,
    chonhinhanh: `Chọn hình ảnh`,
    taive: `Tải về`,
    hopdongdientu: `Giấy đăng ký mở tài khoản giao dịch`,
    hopdongdientutype2: `Giấy đăng ký mở tài khoản\ngiao dịch`,
    xemtruoc: `Xem trước`,
    kydientu: `Ký điện tử`,
    taichuky: `Tải chữ ký`,
    chukycuaban: `Chữ ký của bạn`,
    taihopdongdaky: `Tải hợp đồng đã ký`,
    taihopdongmotaikhoan: `Tải giấy đăng ký mở tài khoản giao dịch`,
    contentdownload: `Quý NĐT vui lòng thực hiện ký điện tử để hoàn thiện Hồ sơ mở tài khoản giao dịch.`,
    contentdownload2: `Giấy đăng ký mở tài khoản giao dịch của Quý khách đã được xác nhận ký điện tử. Quý nhà đầu tư có thể tải Giấy đăng ký mở tài khoản giao dịch đã ký bằng cách nhấn vào nút bên dưới`,
  },
  digitalsignatureipo: {
    giaydangkymuaipo: `Giấy đăng ký mua IPO`,
    taigiaydangkymuaipo: `Tải giấy đăng ký mua IPO`,
    contentdownload: `Giấy đăng ký mua IPO của quý khách được xác nhận ký điện tử. Quý nhà đầu tư có thể tải Giấy đăng ký mua IPO bằng cách nhấn vào nút bên dưới`,
    taigiaydangkymuaipo2: `Vui lòng xem trước thông tin trên Phiếu lấy ý kiến NĐT giai đoạn IPO và thực hiện ký điện tử bằng cách nhấn vào nút 'Ký điện tử' bên dưới`,
  },
  investmentlibraryscreen: {
    thuviendautu: 'Thư viện đầu tư',
    xemthem: 'Xem thêm',
    baivietlienquan: 'Bài viết liên quan',
  },
  feetable: {
    bieuphi: `Biểu phí`,
  },
  softotpscreen: {
    dangkysmartotp: 'Đăng ký smart-OTP',
    dangkymapin: `Đăng ký mã PIN`,
    vuilongnhapmapin: `Vui lòng nhập mã PIN`,
    nhaplaimapin: `Nhập lại mã PIN`,
    xacnhan: `Xác nhận`,
    maxacthucgiaodich: `Mã xác thực giao dịch`,
    smartotp: `Smart OTP`,
    smartotptimeout: `Mã OTP sẽ tự động cập nhật sau`,
  },
  alert: {
    ngaycap: `Ngày cấp không được nhỏ hơn ngày sinh`,
    xoataikhoanthanhcong: `Xoá tài khoản thành công !`,
    vuilongchondayduthongtin: `Vui lòng nhập đầy đủ thông tin !`,
    vuilongnhapdayduthongtinbosung: `Vui lòng nhập đầy đủ thông tin bổ sung !`,
    kythanhcong: `Quý khách đã ký điện tử thành công!`,
    capnhatmucdoruirothanhcong: `Cập nhật mức độ rủi ro thành công !`,
    taochukysothanhcong: `Quý khách đã ký điện tử thành công!`,
    logout: `Đăng xuất`,
    dinhdanganhkhongphuhop: `Hình chữ ký bạn tải lên không đúng định dạng. Vui lòng chọn các file hình có định dạng: .png,.jpg, .jpeg`,
    desau: `Để sau`,
    taithanhcong: `Tải thành công`,
    kydientu: `Ký điện tử`,
    contentlogout: `Bạn chắc chắn muốn đăng xuất?`,
    dong: `Đóng`,
    xoalenh: `Đã xoá lệnh thành công`,
    doimatkhauthanhcong: `Đổi mật khẩu thành công`,
    doiemailthanhcong: `Đổi email thành công`,
    capnhatdiachithanhcong: `Cập nhật địa chỉ thành công`,
    vuilongnhapdayduthongtindiachi: `Vui lòng nhập đầy đủ thông tin địa chỉ`,
    vuilongnhapdayduthongtincanhan: `Vui lòng nhập đầy đủ thông tin cá nhân`,
    capnhatthongtincanhanthanhcong: `Cập nhật thông tin cá nhân thành công`,
    dangxuat: `Đăng xuất`,
    lichxong: `Xong`,
    thongbao: `Thông báo`,
    dungluongtoida: `Dung lượng file tối đa 5MB`,
    vuilongnhapdayduthongtintaikhoannganhang: `Vui lòng nhập đầy đủ thông tin tài khoản ngân hàng`,
    dongy: `Đồng ý`,
    huybo: `Huỷ bỏ`,
    thulai: `Thử lại`,
    vuilongnhapmatkhau: `Vui lòng nhập mật khẩu`,
    vuilongnhaptendangnhap: `Vui lòng nhập tên đăng nhập`,
    dasaochepvaobonhodem: `Đã sao chép thông tin nội dung vào bộ nhớ đệm`,
    dangnhapvantaythatbai: `Tính năng đăng nhập bằng Vân tay/Khuôn mặt chưa được cài đặt.Vui lòng đăng nhập bằng mật khẩu và kiểm tra lại`,
    thatbai: `Thất bại`,
    daxayraloi: `Đã xảy ra lỗi. Vui lòng thử lại`,
    dangkytaikhoanthanhcongekyc: `Quý khách đã đăng ký tài khoản thành công. Hồ sơ của quý khách đã được duyệt.`,
    dangkytaikhoanthanhcong: `Quý khách đã đăng ký tài khoản thành công. Hồ sơ của quý khách sẽ duyệt theo thời gian quy định của Công ty quản lý quỹ.`,
    matkhaucuphaikhacmatkhaumoi: `Mật khẩu cũ phải khác mật khẩu mới`,
    emailcuphaikhacemailmoi: `Email cũ phải khác email mới`,
    xacnhanxoalenhgiaodich: `Xác nhận xoá lệnh giao dịch ?`,
    capnhattaikhoannganhang: `Cập nhật thông tin tài khoản ngân hàng thành công`,
    capnhatthongtintaikhoannganhangthanhcong: `Nhà đầu tư liên hệ với Công ty Quản lý quỹ để cập nhật tài khoản ngân hàng`,
    chuadutuoi: `Theo quy định về độ tuổi được giao dịch Quỹ phải từ 18 tuổi trở lên. Chúng tôi rất tiếc phải từ chối thông tin đăng ký của nhà đầu tư.`,
    nhapotpquasoluong: `Sai OTP quá 3 lần. Vì lý do bảo mật vui lòng đăng nhập lại.`,
    registernhapquaotp: `Giao dịch của Quý Khách đã hết hiệu lực. Vui lòng tạo giao dịch mới`,
    dangnhapsai: `Tên đăng nhập hoặc mật khẩu của Quý khách không đúng`,
    ekycfail: `Chúng tôi không thể xác minh thông tin bạn đang cung cấp vì chất lượng hình chụp không tốt. Vui lòng làm lại!`,
    ekycfail3: `Chúng tôi không thể xác minh thông tin bạn đang cung cấp vì chất lượng hình chụp không tốt. Quý khách vui lòng khai báo thông tin cá nhân thủ công.`,
    hetphiengiaodich: `Hết phiên giao dịch. Vui lòng đăng nhập lại !`,
    capnhatthongtinbosungthanhcong: `Cập nhật thông tin bổ sung thành công !`,
    capnhatungdung: `Vui lòng cập nhật phiên bản mới nhất để sử dụng ${stringApp.appName}`,
    chungminhhethan: `CMND/CCCD của quý khách đã hết hạn`,
    USING_OTHER_DEVICE: `Quý nhà đầu tư đăng nhập trên thiết bị khác. Bạn có muốn nhận thông báo trên thiết bị này`,
    vuilongdangnhapdexemthongbao: `Vui lòng đăng nhập để xem thông báo`,
    chucnangthongbao: `Vui lòng vào cài đặt để bật thông báo!`,
    chucnangvantay: ``,
    mapinkhongdung: `Mã PIN không đúng. Vui lòng nhập lại!`,
    dangkythanhcongsmartotp: `Quý khách đã đăng ký thành công Smart OTP`,
    huythanhcongsmartotp: `Quý khách đã huỷ thành công Smart OTP`,
    vuilongtruycapvaocaidat: `Vui lòng truy cập vào cài đặt!`,
    sudungchucnangsmartotp: `Để sử dụng chức năng này, vui lòng vào Cài đặt để bật thông báo!`,
    thietbikhonghople: `Thiết bị không hợp lệ ! Quý khách vui lòng sử dụng bản Web`,
  },

  component: {
    ngay: `Ngày`,
    thang: `Tháng`,
    nam: `Năm`,
    goiy: `Gợi ý`,
    currency: `${stringApp.currency}`,
    tren: 'Trên',
    tu: 'Từ',
    ngaytrolen: 'Ngày trở lên',
    duoi: 'Dưới',
    nhohonhoacbang: 'Nhỏ hơn hoặc bằng',
    trolen: 'trở lên',
    ngay1: 'ngày',
    chuaxacthuc: 'Chưa xác thực',
    INVESTMENT_PROFILE_PENDING: 'Chờ duyệt',
    INVESTMENT_PROFILE_APPROVE: 'Đã duyệt',
    INVESTMENT_PROFILE_ACCEPT: 'Đã duyệt',
    INVESTMENT_PROFILE_REJECT: 'Từ chối',
    dangnhapbangvantay: `Sử dụng Vân tay/Khuôn mặt để mở khoá ứng dụng ${stringApp.appName}`,
    dangnhapvaoapp: `Đăng nhập ${stringApp.appName}`,
    daky: 'Đã ký',
    chuaky: 'Chưa ký',
    timkiemquydautu: 'Tìm kiếm quỹ đầu tư',
    chuongtrinhdaututudongvalientuc:
      'là chương trình đầu tư tự động và liên tục',
    lenhmuadinhky: `- Quý khách không cần tạo lệnh mua mà chỉ thực hiện chuyển tiền vào tài khoản nhận tiền của Quỹ để thực hiện lệnh mua định kỳ.`,
    luuy: 'Lưu ý',
    thoidiemnhantien: `Thời điểm nhận tiền cuối cùng của kỳ tháng `,
    truoc: 'trước',
    kichhoatvantay: `Kích hoạt vân tay/khuôn mặt để đăng nhập ứng dụng`,
    vantaykhuonmat: `Vân tay/Khuôn mặt cho ${stringApp.appName}`,
    goi: `Gọi`,
    dangthamgia: `Đang tham gia`,
    dadung: 'Đã dừng',
    riskAssessment: `riskAssessment`,
    huysmartotp: `Huỷ smartOTP`,
    Dividend: `Dividend`,
    thaydoivantay: `Nhận diện sinh trắc học trên thiết bị đã thay đổi, vui lòng đăng nhập lại bằng mật khẩu !`,
  },
  ERROR_BE: {
    OK: `OK`,
    NOT_DELETE: `Đây là tài khoản admin root nên không thể xóa`,
    CREATED: `Quý khách đã đăng ký tài khoản thành công`,
    SERVICE_STOPPING: `Quý khách vui lòng đợi trong giây lát. Yêu cầu của Quý khách đang đang được xử lý`,
    NO_CONTENT: `Hiện tại, Hệ thống không tìm thấy dữ liệu Quý khách đang tìm kiếm`,
    NOT_FOUND: `Hiện tại, Hệ thống không tìm thấy dữ liệu Quý khách đang tìm kiếm`,
    NOT_LOGIN: `Bạn chưa đăng nhập hệ thống nên không thể Đăng xuất`,
    INVALID_CONFIRM_TOKEN: `Mã xác thực OTP không đúng`,
    BAD_NEW_PASSWORD: `Mật khẩu phải có ít nhất 6 ký tự nhưng không dài hơn 40 ký tự và không có khoảng trắng. Vui lòng xem quy tắt đặt Mật khẩu bên dưới`,
    CONFIRM_PASSWORD_NOT_MATCH: `Xác nhận lại mật khẩu không khớp với Mật khẩu`,
    USER_IS_INACTIVE: `Quý khách chưa được kích hoạt tài khoản Đăng nhập Đầu tư. Vui lòng liên hệ nhân viên tư vấn để được hỗ trợ`,
    USER_IS_WAITING_ADMIN_APPROVE: `Tài khoản đăng nhập của Quý khách đang chờ phê duyệt. Quý khách vui lòng liên hệ nhân viên tư vấn để được hỗ trợ`,
    INVALID_CAPTCHA: `Captcha nhập/chọn sai`,
    INVALID_OLD_EMAIL: `Email cũ của bạn nhập không chính xác. Vui lòng kiểm tra lại!`,
    BAD_REQUEST: `Đường dẫn không hợp lệ`,
    INVALID_USER: `Tên đăng nhập hoặc mật khẩu của Quý khách không đúng`,
    INVALID_IP: `IP này không được phép đăng nhập. Vui lòng sử dụng IP được FPlatform cung cấp`,
    CAN_NOT_SAVE_USER: `Hệ thống đăng ký tài khoản đăng nhập không thành công. Quý khách vui lòng kiểm tra lại thông tin và thực hiện lại`,
    INVALID_ADMIN_GROUP: `Thông tin Nhóm Admin không tồn tại`,
    CONFLICT_ADMIN_GROUP_CODE: `Thông tin Mã Nhóm Admin đã tồn tại`,
    INVALID_CONFIRM_URL: `Đường dẫn không hợp lệ`,
    INVALID_ADMIN_RESOURCE: `Admin Resource Không tồn tại`,
    CONFLICT_ADMIN_RESOURCE: `Admin Resource đã tồn tại`,
    INVALID_SOCIAL_TYPE: `SocialType không tồn tại`,
    CONFLICT_SOCIAL_TYPENAME: `Tên SocialType đã tồn tại`,
    INVALID_ADMIN: `Tài khoản Admin không tồn tại`,
    CONFLICT_ADMIN_ACCOUNT: `Tài khoản Admin account đã tồn tại`,
    INVALID_FUND_OPERATOR: `Tài khoản Fund Operator không tồn tại`,
    CONFLICT_FUND_OPERATOR: `Tài khoản Fund Operator account đã tồn tại`,
    INVALID_CONFIG: `Config không tồn tại`,
    CONFLICT_CONFIG_NAME: `Tên Config đã tồn tại`,
    INVALID_USER_TYPE: `User type không tồn tại`,
    INVALID_STATUS: `Status không tồn tại`,
    INVALID_INVESTOR_TYPE: `Loại Nhà đầu tư chưa được định nghĩa`,
    INVALID_ISSUER_TYPE: `Nơi cấp giấy tờ chưa được định nghĩa`,
    INVALID_DATA_PROVINCE: `Tỉnh thành Quý khách đang chon chưa được định nghĩa`,
    INVALID_DATA_COUNTRY: `Quốc gia đang chọn chưa được định nghĩa`,
    INVALID_ADMIN_BANK: `Ngân hàng (Bank) chưa được định nghĩa`,
    CONFLICT_ADMIN_BANK_NUMBER: `Tài khoản ngân hàng không tồn tại`,
    INVALID_DATA_BANK: `Ngân hàng (Bank) chưa được định nghĩa`,
    CONFLICT_DATA_BANK_STOCK_NAME: `Tên viết tắt Ngân hàng (Bank) chưa được định nghĩa`,
    INVALID_PRODUCT: `Sản phẩm Quỹ chưa được khai báo trong hệ thống`,
    CONFLICT_PRODUCT_CODE: `Mã sản phẩm Quỹ chưa được khai báo trong hệ thống`,
    CAN_NOT_DELETE_INVESTOR: `Nhà đầu tư đang có tài sản nên không thể xóa`,
    UPLOAD_CONTRACT_FAIL: `Tạm thời không thể upload hợp đồng lên máy chủ. Vui lòng kiểm tra email để nhận file hợp đồng.`,
    INVALID_SEARCH_TIME: `Thời gian tìm kiếm không hợp lệ`,
    INVALID_MAXIMUM_PERIOD: `Vui lòng chọn khoảng thời gian không quá 180 ngày`,
    INVALID_ORDER: `Giao dịch không tồn tại`,
    CONFLICT_ORDER_CODE: `Mã Giao dịch không tồn tại`,
    INVALID_ORDER_TYPE: `Loại giao dịch không tồn tại`,
    INVALID_OTP: `Mã xác thực OTP không đúng`,
    EXPIRED_OTP: `Mã xác thực OTP hết hạn. Vui lòng lấy lại mã xác thực OTP khác`,
    OTP_LOCKED: `Giao dịch của Quý Khách đã hết hiệu lực. Vui lòng tạo giao dịch mới`,
    INVALID_PHONE: `Số điện thoại không hợp lệ`,
    INVALID_USER_REF_CODE: `Mã giới thiệu không hợp lệ`,
    OTP_IS_SENT: `Mã xác thực OTP đã được gửi tới số điện thoại của Quý khách`,
    OTP_IS_NOT_SENT: `Mã xác thực OTP chưa gửi    `,
    OPT_INVALID_CLIENT_ID: `Mã ứng dụng khách không hợp lệ`,
    OTP_INVALID_TRANS_ID: `Mã giao dịch không hợp lệ`,
    INVALID_USER_STATUS: `Tài khoản chưa đăng nhập`,
    PASSWORD_NOT_MATCH: `Mật khẩu cũ không đúng`,
    INVALID_PRODUCT_STATUS: `Trạng thái sản phẩm không tồn tại`,
    INVALID_EMAIL_SUBJECT: `Tiêu đề email không đúng`,
    INVALID_EMAIL: `Email không đúng định dạng`,
    NOT_EXISTS_EMAIL: `Email không tồn tại`,
    EMAIL_ALREADY_REGISTERED: `Email này đã đăng ký. Quý khách vui lòng sử dụng email khác`,
    PHONE_ALREADY_REGISTERED: `Số điện thoại này đã đăng ký. Quý khách vui lòng sử dụng số điện thoại khác`,
    INVALID_PHONE_POSTAL: `Mã vùng điện thoại không hợp lệ`,
    PHONE_IS_NOT_VIETNAM: `Vui lòng nhập số điện thoại đăng ký ở Việt Nam`,
    EMAIL_NOT_REGISTER: `Email chưa được đăng ký trong hệ thống`,
    INVALID_INVESTMENT_PROFILE: `Tài khoản đầu tư của Quý khách không tồn tại hoặc chưa được duyệt`,
    INVALID_USER_BANK_ACCOUNT: `Thông tin Nhà đâu tư không đủ hoặc không chính xác`,
    CONFLICT_BUY_AUTO: `Chương trình đầu tư đã được đăng ký đặt lệnh tự động`,
    INVALID_ID_TYPE: `Loại giấy tờ không tồn tại`,
    INVALID_PHOTO: `Thông tin giấy tờ không hợp lệ`,
    INVESTMENT_PROFILE_CANT_CHANGE: `Trạng thái tài khoản đầu tư đang chờ duyệt hoặc đã được duyệt`,
    INVALID_BANK: `Thông tin ngân hàng không tồn tại`,
    INVALID_DATA_DISTRICT: `Thông tin Quận/Huyện không tồn tại`,
    INVALID_DATA_WARD: `Thông tin Phường/Xã không tồn tại`,
    INVALID_USER_INFO: `Thông tin tài khoản không hợp lệ`,
    CONFLICT_ID_NO: `Số giấy tờ đã tồn tại`,
    INVALID_INVESTMENT_PROFILE_WHEN_CHECK_ID_NO: `Thông tin nhà đầu tư không được tìm thấy.`,
    INVALID_ID_TYPE_FOR_USER_TYPE: `Loại giấy tờ không đúng với loại tài khoản`,
    INVALID_PRODUCT_PROGRAM: `Chương trình đầu tư không tồn tại`,
    INVALID_BUY_MONEY: `Số tiền mua phải lớn hơn hoặc bằng số tiền mua tối thiểu theo quy định của Quỹ    `,
    INVALID_SELL_PROGRAM: `Chương trình đầu tư chưa kích hoạt`,
    INVALID_SELL_PRODUCT: `Sản phẩm chưa kích hoạt `,
    INVALID_SWITCH_PROGRAM: `Chương trình đầu tư của Quỹ này không cho phép chuyển đổi qua Quỹ khác`,
    INVALID_BUY_SCHEME: `Chương trình đầu tư không cho đặt lệnh tự động`,
    INVALID_SOURCE: `Thông tin Nguồn (Source) không hợp lệ`,
    INVALID_SELL_VOLUME: `Số chứng chỉ quỹ bán phải lớn hơn hoặc bằng số lượng bán tối thiểu theo quy định của Quỹ`,
    INVALID_VOLUME: `Số chứng chỉ quỹ (CCQ) khả dụng không đủ so với số lượng Quý khách đặt lệnh`,
    INVALID_VOLUME_HOLDING_MIN: `Số chứng chỉ quỹ (CCQ) nắm giữ tối thiểu phải lớn hơn hoặc bằng số lượng nắm giữ tối thiểu theo quy định của Quỹ. Hoặc Quý khách  có thể bán hết số CCQ đang nắm giữ`,
    INVALID_CUT_OFF_TIME: `Quý khách không thể xóa lệnh vì hệ thống đã đóng lệnh của phiên giao dịch này`,
    INVALID_ORDER_STATUS: `Giao dịch đã được xác nhận hoặc đã được phân bổ`,
    INVALID_TRANSACTION: `Giao dịch không tồn tại`,
    INVALID_DEST_PRODUCT: `Sảm phẩm Quỹ mục tiêu không tồn tại. Quý khách vui lòng chọn lại Quỹ mục tiêu khác`,
    INVALID_SELL_DEST_PRODUCT: `Sản phẩm Quỹ mục tiêu chưa kích hoạt`,
    INVALID_DES_PRODUCT_PROGRAM: `Chương trình đầu tư mục tiêu không tồn tại`,
    INVALID_SELL_DES_PROGRAM: `Chương trình đầu tư mục tiêu chưa kích hoạt`,
    INVALID_SWITCH_SAME_PROGRAM: `Lệnh Chuyển đổi chứng chỉ quỹ phải cùng loại chương trình đầu tư`,
    INVALID_VOLUME_BALANCE: `Số chứng chỉ quỹ còn lại nhỏ hơn số bán tối thiểu. Quý khách có thể thực hiện bán toàn bộ Chứng chỉ quỹ (CCQ) đang có`,
    INVALID_PASSWORD: `Mật khẩu cũ không đúng`,
    INVALID_VOLUME_SELL: `Tổng số lượng chứng chỉ quỹ (CCQ) bán không được vượt 10% tổng tài sải Quỹ`,
    EXPORT_ERROR: `Lỗi trong quá trình xử lý`,
    INVALID_APPROVE_RECEIVED_MONEY_ERROR: `Mã giao dịch không tồn tại`,
    INVALID_INVESTMENT_HARD_PROFILE: `Công Ty chưa nhận hồ sơ gốc. Quý khách vui lòng liên hệ nhân viên tư vấn để được hỗ trợ`,
    INVALID_TEMPLATE: `Mẫu báo cáo không tồn tại`,
    INVALID_ACCOUNT_TYPE: `Account type không tồn tại`,
    INVALID_EXCEL_DATA: `Dữ liệu của file không tồn tại`,
    INVALID_TRADING_TIME: `Phiên giao dịch không khớp. Vui lòng kiểm tra dữ liệu file import`,
    INVALID_TRADING_TIME_FORMAT: `Phiên giao dịch không đúng định dạng`,
    INVALID_R44_APPROVE: `Có lỗi trong quá trình phân bổ`,
    EXECUTED_R44: `Quỹ này đã được phân bổ tại phiên trên, Vui lòng kiểm tra lại`,
    INVALID_PORTAL: `Có lỗi trong quá trình liên kết qua portal service`,
    INVALID_PORTAL_API_DIGITAL_ACCOUNT: `Thông tin tài khoảng digital không hợp lệ`,
    PORTAL_API_DIGITAL_ACCOUNT_EXISTED: `Thông tin tài khoảng digital đã tồn tại`,
    PORTAL_API_DIGITAL_ACCOUNT_NOT_EXISTED: `Thông tin tài khoảng digital không tồn tại`,
    INVALID_ID_NO_ACCOUNT_NO: `Số giấy tờ hoặc số tài khoảng không hợp lệ`,
    ID_NO_ALREADY_REGISTERED: `Số giấy tờ này đã đăng ký. Quý khách vui lòng sử dụng Số giấy tờ khác`,
    INVALID_CHANNEL: `Quý khách không thể xóa lệnh vì lệnh này được tạo thông qua đối tác`,
    PORTAL_API_DIGITAL_ACCOUNT_ACTIVATED: `Thông tin tài khoảng digital khác đã được kích hoạt`,
    INVALID_IMAGE_TYPE: `Hình chữ ký bạn tải lên không đúng định dạng. Vui lòng chọn các file hình có định dạng: .png,.jpg, .gif, .jpeg`,
    SIGN_ERROR: `Có lỗi trong quá trình ký điện tử. Vui lòng thử lại`,
    SIGNATURE: `Đã ký/Đã nhận`,
    INVALID_SWTICH_BUY_MONEY: `Số tiền mua nhỏ hơn số tiền mua tối thiểu của quỹ mục tiêu`,
    INVALID_VOLUME_BALANCE_LIMO: `Số chứng chỉ quỹ còn lại không đúng theo quy định. Quý khách có thể thực hiện bán toàn bộ Chứng chỉ quỹ (CCQ) đang có hoặc số    dư tối thiểu phải từ 100 trở lên.`,
    INVALID_SWITCH_VOLUME_BALANCE_LIMO: `Số chứng chỉ quỹ còn lại không đúng theo quy định. Quý khách có thể thực hiện chuyển đổi toàn bộ Chứng chỉ quỹ (CCQ)  đang có.`,
    DUPLICATE_USERNAME: `Username không được trùng, vui lòng chọn một username khác!`,
    DUPLICATE_EMAIL: `Email không được trùng, vui lòng chọn một email khác!`,
    DUPLICATE_PHONE: `Số điện thoại không được trùng, vui lòng chọn một số điện thoại khác!`,
    DUPLICATE_ID_NO: `Số hiện giấy tờ không được trùng. Vui lòng kiểm tra lại!`,
    DUPLICATE_ACCOUNT: `Username đã được đăng ký, vui lòng chọn một username khác!`,
    REQUIRED_CLIENT_ID: `Client id không hợp lệ`,
    CLIENT_ID_NOT_EXISTED: `Client id không tồn tại`,
    INVALID_HASH_NOT_MATCH: `Hash không đúng`,
    REQUIRED_HASH: `Hash không hợp lệ`,
    REQUIRED_INVESTMENT_NUMBER: `Số tài khoản nhà đầu tư không hợp lệ`,
    REQUIRED_FT_NO: `Số FT không hợp lệ`,
    ORDER_RECEIVED_MONEY: `Giao dịch đã nhận tiền trước đó`,
    INVALID_PORTAL_TRADING_TIME: `Phiên giao dịch không hợp lệ`,
    INVALID_TRANSACTION_DELETE: `Giao dịch đã được xóa trước đó`,
    INVALID_TRANSACTION_FILTER: `Vui lòng nhập nguồn và từ ngày đến ngày để xuất phiên bạn cần`,
    SYSTEM_BUSY: `Hien tai he thong dang ban thuc hien tac vu khac. Vui long thu lai sau 5 phut.`,
    INVALID_TRADING_TIME_EXPORT_FORMAT: `Phiên giao dịch không đúng định dạng Ngày/Tháng/Năm`,
    INVALID_SWITCH_SAME_PRODUCT: `Lệnh Chuyển đổi chứng chỉ quỹ không được cùng quỹ`,
    INVALID_SWITCH_IN_TRADING_TIME: `Không tìm thấy phiên của lệnh mua chuyển đổi`,
    INVALID_PERMANENT_ADDRESS_INFO: `Địa chỉ thường trú không hợp lệ`,
    INVALID_MAILING_ADDRESS_INFO: `Địa chỉ liên hệ không hợp lệ`,
    INVALID_NEWS_CODE: `Mã tin tức không hợp lệ`,
    INVALID_DEVICE_INFO: `Thông tin device không tồn tại`,
    REQUIRED_PRODUCT: `Thông tin sản phẩm không hợp lệ`,
    REQUIRED_PROGRAM: `Thông tin Chương trình không hợp lệ`,
    REQUIRED_FEE_TYPE_CODE: `Mã phí không hợp lệ`,
    UNAUTHENTICATED: `Yêu cầu chưa được xác thực`,
    UNAUTHORIZED: `Yêu cầu chưa được xác thực`,
    FORBIDDEN: `Không có quyền truy cập`,
    CONFLICT_USER_EMAIL: `Email đã tồn tại trong hệ thống`,
    INVALID_RM_PROFILE: `Thông tin RM không đúng`,
    INVALID_BDA_PROFILE: `Thông tin BDA không đúng`,
    NOT_EXISTED_BDA_PROFILE: `Thông tin BDA không tồn tại`,
    NOT_EXISTED_RM_PROFILE: `Thông tin RM không tồn tại`,
    INVALID_FORMAT_FILE: `Format file không hỗ trợ`,
    INVALID_BANK_INFO: `Thông tin đối tác không tồn tại`,
    PARTNER_ACCESS_RIGHT_EXISTED: `Menu đã tồn tại`,
    INVALID_MENU_INFO: `Thông tin Menu không đúng`,
    INVALID_ROLE_INFO: `Thông tin Role không đúng`,
    INVALID_MANAGER_USER_PROFILE: `Thông tin tài khoản đối tác không đúng`,
    INVALID_OPERATOR: `Biểu thức toán học không tồn tại`,
    INVALID_BRANCH_INFO: `Thông tin chinh nhánh không tồn tại`,
    CONFLICT_PARTNER_CODE: `Mã Đối tác đã tồn tại`,
    CONFLICT_PARTNER_BRANCH_CODE: `Mã Chi nhánh đã tồn tại`,
    CONFLICT_PORTAL_USER_CODE: `Mã Nhân viên đã tồn tại`,
    NOT_EXISTED_PORTAL_ORDER: `Giao dịch không tồn tại`,
    INVALID_PORTAL_ORDER_STATUS: `Trạng thái Giao dịch không được từ chối`,
    NOT_EXISTED_RESET_KEY: `Mã kích hoạt không tồn tại`,
    DELETED_BUY_AUTO: `Chương trình đầu tư đăng ký đặt lệnh tự động đã hết hiệu lực|Your SIP is expired`,
    RECONCILED_BUY_AUTO: `Chương trình đầu tư đăng ký đặt lệnh tự động đã được khớp trong kỳ|Your SIP already summit for this current trading session`,
    SUB_SUPERVISORY_BANK_ACCOUNT_EXISTED: `Đầu số đã tồn tại!`,
    EXISTED_INVESTOR_BY_RM: `RM đã được phân bổ quản lý nhà đầu tư`,
    INVALID_EMPTY_FILE: `File không dữ liệu`,
    INVALID_BRANCH: `Chi nhánh không tồn tại`,
    EXISTED_RM_BY_BRANCH: `RM đã được tạo cho chi nhánh khác`,
    EXISTED_DUPLICATE_INVESTOR: `Hệ thống tìm được nhiều dữ liệu, Quý khách vui lòng nhập Số tài khoản giao dịch CCQ`,
    VALIDATE_KYC: `Tài khoản của Quý nhà đầu tư chưa được duyệt`,
    VALIDATE_TEMPLATE: `Template không tồn tại`,
    INVALID_BASE_64: `file image base 64 không hợp lệ`,
    INVALID_AGENT_CODE: `Đầu số Đại lý không hợp lệ`,
    INVALID_RISK_VALUE: `Thông tin rủi ro không hợp lệ`,
    REQUIRED_SOURCE: `Thông tin đối tác không hợp lệ`,
    NOT_EXISTED_SUBSCRIPTION_FEE: `Thông tin phí mua không tồn tại`,
    INVALID_YEAR_STATS: `Định dạng năm không đúng`,
    INVALID_ID: `Số giấy tờ không hợp lệ`,
    REQUIRED_SOURCE_BRANCH: `Thông tin Chi nhánh không hợp lệ`,
    REQUIRED_ORDER_CODE: `Mã giao dịch không hợp lệ`,
    INVALID_CLIENT_ID: `Client id is required`,
    INVALID_ID_NO: `Id no is required`,
    INVALID_CELL_PHONE: `Cell phone is required`,
    INVALID_OTP_CODE: `Opt code is required`,
    INVALID_VERIFY_ID: `Verification ID is required`,
    INVALID_FULL_NAME: `Full name is required`,
    INVALID_GENDER: `Gender is required`,
    INVALID_INVESTOR_ID: `Investor Id is required`,
    INVALID_ACCOUNT_NO: `Acccount No is required`,
    INVALID_NATIONAL_ID: `Nationality is required`,
    INVALID_DOB: `Dob is required    `,
    INVALID_ID_DATE_OF_ISSUE: `Id_date_of_issue is required    `,
    INVALID_ID_PLACE_OF_ISSUE: `Id_place_of_issue is required`,
    INVALID_PERMANENT_ADDRESS: `Permanent address is required`,
    INVALID_MAILING_ADDRESS: `Mailing address is required`,
    INVALID_EMAIL_DIGITAL: `Email is required`,
    INVALID_BANK_ACCOUNT_NO: `Bank account no is required`,
    INVALID_BANK_ID: `Bank id is required`,
    INVALID_BANK_BRANCH: `Bank branch id is required   `,
    INVALID_HASH: `Hash is required`,
    INVALID_FUND_ID: `Fund Id is required`,
    INVALID_UNITS: `Units Id is required`,
    INVALID_TRANSACTION_ID: `Transaction Id is required`,
    INVALID_PARTNER_TRANSACTION_ID: `Partner transaction Id is required`,
    INVALID_JSON_FORMAT: `Json format invalid`,
    PROGRAM_NOT_SUPPORT: `Program does not support`,
    INVALID_AUM_COMMON_INFO: `Xin hãy điền đầy đủ thông tin.`,
    INVALID_AUM_VALUE_INFO: `Giá trị bắt đầu phải nhỏ hơn giá trị kết thúc.`,
    MENU_CHILDREN_NOT_EXISTED: `Menu không tồn tại!`,
    EMPLOYEE_ACCESS_RIGHT_EXISTED: `Menu đã được đăng ký!`,
    EMPLOYEE_ACCESS_RIGHT_NOT_EXISTED: `Role không tồn tại!`,
    INVALIDE_FO_INFO: `Thông tin user không đúng!`,
    INVALIDE_FO_ROLE: `Tài khoản không có quyền phân bổ cho đại lý khác!`,
    INVALID_GENDER_FORMAT: `Gender does not format`,
    INVALID_DOB_FORMAT: `Dob does not format`,
    INVALID_ID_DATE_OF_ISSUE_FORMAT: `Id_date_of_issue does not format`,
    INVALID_ANNUAL_INCOME_FORMAT: `Annual income does not format`,
    INVALID_TRANSACTION_ID_FORMAT: `Transaction Id does not format`,
    FUND_ID_NOT_MATCH: `Fund Id does not match`,
    OTP_NOT_EXISTED: `Verification ID is not existed`,
    OTP_TIME_OUT: `OTP time out`,
    OTP_INCORRECT: `OTP is incorrect`,
    CONFIRM_TOKEN_NOT_MATCH: `OTP TransId code not match`,
    EXECUTION_SUCCESS: `SUCCESS`,
    EXECUTABLE_SUCCESS: `SUCCESS`,
    INTERNAL_SERVER_ERROR: `Có lỗi khi tải dữ liệu.`,
    OTP_DELIVERY_ERROR: `Hệ thống không thể gửi mã xác thực OTP tại thời điểm hiện tại. Quý khách Vui lòng thử lại sau`,
    EXTERNAL_SERVICE_FAIL: `Lỗi dịch vụ trung gian`,
    DUPLICATE_PROGRAM_SIP: `Trùng chương trình SIP`,
    DUPLICATE_VSDCODE: `Trùng mã chu kì giao dịch`,
    DUPLICATE_PROGRAM: `Trùng chương trình`,
    DUPLICATE_TRADING_CODE: `Trùng mã giao dịch`,
    INVALID_USERNAME: `Tên đăng nhập của Quý khách không đúng`,
    NOT_EXISTING_ID_NO_ACCOUNT_NO: `Số giấy tờ hoặc số tài khoản không tồn tại`,
    CAN_NOT_UPDATE_INVESTOR: `Nhà đầu tư đã được duyệt, không được phép chỉnh sửa.`,
    FIRE_BASE_CONFIG_NOT_FOUND: `Không tìm thấy tệp cấu hình của FireBase hoặc tệp không hợp lệ.`,
    TIMER_DATE_MUST_GREATER_CURRENT_DATE: `Thời gian hẹn giờ phải lớn hơn thời gian hiện tại.`,
    NOT_FOUND_DEVICE_TOKEN: `Không tìm thấy token của thiết bị.`,
    NOT_FOUND_NOTIFICATION: `Không tìm thấy thông báo đẩy.`,
    CANNOT_UPDATE_NOTIFICATION_DONE: `Không thể cập nhật cho thông báo đẩy đã hoàn thành.`,
    NOT_TIMER_NOTIFICATION: `Thông báo đẩy này không phải là thông báo có hẹn giờ.`,
    TIMER_EXPIRED_CANNOT_UPDATE_NOTIFICATION: `Bộ hẹn giờ đã hết hạn. Không thể cập nhật thông báo.`,
    NOT_FOUND_USER_INBOX: `Tin nhắn thông báo này không tồn tại`,
    INVALID_OLD_PASSWORD: `Mật khẩu cũ không chính xác`,
    GLOBALCODE_ALREADY_REGISTERED: `Mã nhà đầu tư đã tồn tại`,
    IPO_FUNDS_IS_NOT_EXIST: `Không tồn tại Quỹ đang trong giai đoạn IPO.`,
    IPO_FUNDS_IS_NOT_ESGIN: `Vui lòng thực hiện ký vào PHIẾU LẤY Ý KIẾN NHÀ ĐẦU TƯ để có thể mua được sản phẩm trong giai đoạn IPO. Nếu không tồn tại mục này, vui lòng update ứng dụng trong Apple Store hoặc Android Store. Xin cảm ơn.`,
    USER_ADDITIONAL_INFO_PROFESSIONAL: `Quy nhà đầu vui lòng liên hệ công ty để bổ sung hồ sơ`,
    FATCA_NONE_ESIGN: `Quý NĐT vui lòng kiểm tra lại tình trạng FATCA. Lighthouse Capital sẽ liên hệ với Quý NĐT để bổ sung hồ sơ.`,
    TRANSACTION_VALIDATE_KYC: `Quý NĐT vui lòng thực hiện ký điện tử để hoàn thiện Hồ sơ mở tài khoản giao dịch.`,
    TRANSACTION_VALIDATE_APPROVE: `Tài khoản của Quý NĐT đang trong tình trạng chờ duyệt. Quý NĐT chưa thể thực hiện giao dịch trong khoảng thời gian này.`,
    USING_OTHER_DEVICE_SMART_OTP: `Quý nhà đầu tư đăng nhập trên thiết bị khác. Vui lòng đăng ký Smart OTP trên thiết bị mới`,
    INVESTMENT_PROFILE_APPROVE_EKYC: `Tài khoản của Quý khách đã được tạo thành công.Thông tin giấy đăng ký mở tài khoản giao dịch và FATCA 9 của Quý khách sẽ được gửi tới email: `,
    INVESTMENT_PROFILE_PENDING_EKYC: `Tài khoản của Quý khách hiện đang chờ duyệt. Vui lòng kiếm tra thông tin xác nhận được gửi tới email: `,
    hoacquykhachtaitaiday: `hoặc quý khách tải tại đây`,
    INVALID_SWITCH_DES_PROGRAM: `Quỹ mục tiêu không áp dụng Chương trình đầu tư định kỳ (SIP). Quý Nhà đầu tư vui lòng thay đổi chương trình của Quỹ mục tiêu hoặc liên hệ hotline ${stringApp.hotline} để được hỗ trợ.`,
  },
};
