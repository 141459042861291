import {Button, Div, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React from 'react';
import {useAppSelector} from 'store/hooks';

const ComCheck = (p: {
  accept: boolean;
  setIsAccept: () => void;
  content: string;
}) => {
  const {accept, setIsAccept, content} = p;
  return (
    <>
      <Button
        widthHeight={25}
        onPress={() => {
          setIsAccept();
        }}
        marginRight={13}
        borderWidth={1}
        alignItems={'center'}
        justifyContent={'center'}
        borderColor={accept ? Ecolors.mainColor : Ecolors.spaceColor}
        borderRadius={25}>
        <ImageView
          source={accept ? Icons.check : Icons.uncheck}
          widthHeight={20}
          tintColor={accept ? Ecolors.mainColor : Ecolors.grayColor}
        />
      </Button>
      <Div flex={1}>
        <Label multilanguage={false}>{content}</Label>
      </Div>
    </>
  );
};

function AcceptFatcaCom(p: {
  isAccept: boolean;
  isAcceptFatca: boolean;
  setIsAccept: (t: boolean) => void;
  setIsAcceptFatca: (t: boolean) => void;
}) {
  const I18nState = useAppSelector(state => state.languages.I18nState);
  const {isAcceptFatca, setIsAcceptFatca, setIsAccept, isAccept} = p;

  const contentFatca1 =
    I18nState == 'vi'
      ? `Nhà đầu tư là công dân Mỹ hoặc tổ chức được thành lập hoặc có hoạt động tại Mỹ (FATCA)`
      : `The investor is a US citizen or an entity established or operating in the United States (FATCA)`;
  const contentFatca2 =
    I18nState == 'vi'
      ? `Nhà đầu tư không là công dân Mỹ hoặc tổ chức được thành lập hoặc có hoạt động tại Mỹ (NONE FATCA)`
      : `The investor is not a US citizen or an organization established or operating in the US (NONE FATCA)`;
  const contentAccept =
    I18nState == 'vi'
      ? `Tôi đồng ý với điều khoản trên`
      : `I agree to the terms and conditions.`;

  return (
    <Div>
      <Div
        flexDirection={'row'}
        width={'100%'}
        paddingVertical={17}
        alignItems={'center'}
        justifyContent={'flex-start'}>
        <ComCheck
          accept={!isAcceptFatca}
          setIsAccept={() => setIsAcceptFatca(false)}
          content={contentFatca1}
        />
      </Div>
      <Div
        flexDirection={'row'}
        width={'100%'}
        paddingBottom={24}
        alignItems={'center'}
        justifyContent={'flex-start'}>
        <ComCheck
          accept={isAcceptFatca}
          setIsAccept={() => setIsAcceptFatca(true)}
          content={contentFatca2}
        />
      </Div>
      <Div
        flexDirection={'row'}
        width={'100%'}
        borderTopColor={Ecolors.bordercolor}
        borderTopWidth={1}
        paddingBottom={24}
        paddingTop={17}
        alignItems={'center'}
        justifyContent={'flex-start'}>
        <ComCheck
          accept={isAccept}
          setIsAccept={() => setIsAccept(!isAccept)}
          content={contentAccept}
        />
      </Div>
    </Div>
  );
}

export default React.memo(AcceptFatcaCom);
